import { landingRoute, i18n, slashCheck, asyncComponent } from 'helpers/router';

import loadable from '@loadable/component';
import landingRoutes from './landing-routes';

export default function ({ amp } = {}) { // eslint-disable-line
  return i18n({
    path: '/',
    component: loadable(() => import(/* webpackChunkName: "body" */ './body')),
    indexRoute: {
      component: loadable(() => import(/* webpackChunkName: "home" */ './page-home')),
      noLowerMenu: true,
      mods: 'pageFill'
    },
    childRoutes: [
      {
        path: 'akcii',
        onEnter: slashCheck,
        component: loadable(() => import(/* webpackChunkName: "old-stocks" */ './section-stocks'))
      },
      // converter
      {
        path: 'finansy/konvertor-valyut.html',
        onEnter: slashCheck,
        component: loadable(() => import(/* webpackChunkName: "section-finance" */ './section-finance/converter')),
        mods: 'pageFill'
      },
      {
        path: 'under-construction',
        // mods: 'pageFill',
        onEnter: slashCheck,
        component: loadable(() => import(/* webpackChunkName: "construction" */ './section-construction'))
      },
      {
        path: 'tariff-plans',
        // mods: 'pageFill',
        onEnter: slashCheck,
        component: loadable(() => import(/* webpackChunkName: "tariff-plans" */ './section-tariff-plans'))
      },
      /*
      {
        path: 'realtor',
        onEnter: slashCheck,
        component: loadable(() => import(/!* webpackChunkName: "realtor" *!/ './section-sandbox-realtor')),
        mods: 'pageFill'
      },
      {
        path: 'realtors',
        onEnter: slashCheck,
        component: loadable(() => import(/!* webpackChunkName: "realtors" *!/ './section-sandbox-realtors')),
        // mods: 'pageFill'
      },
      */
      // User's profile functionality
      {
        path: 'profile',
        getChildRoutes: asyncComponent(() => import(/* webpackChunkName: "routes-profile" */ './section-profile/routes'))
      },
      {
        path: 'nedvizhimost',
        getChildRoutes: asyncComponent(() => import(/* webpackChunkName: "nedvizhimost" */ './section-objects/routes'))
      },
      // contacts
      {
        path: 'contacts',
        onEnter: slashCheck,
        component: loadable(() => import(/* webpackChunkName: "section-contacts" */ './section-contacts')),
        mods: 'pageFill'
      },
      // О компании
      {
        path: 'o-kompanii',
        mods: 'pageFill',
        onEnter: slashCheck,
        component: loadable(() => import(/* webpackChunkName: "about" */ './section-about'))
      },
      // terms of use
      {
        path: 'copyright.html',
        component: loadable(() => import(/* webpackChunkName: "section-terms" */ './section-terms')),
        // mods: 'pageFill'
      },
      {
        path: 'unsubscribe',
        component: loadable(() => import(/* webpackChunkName: "routes-unsubscribe" */ 'subsys/notifications/page-unsubscribe'))
      },
      {
        path: 'kompanii/*',
        onEnter: (route, redirect) => {
          return redirect('/');
        }
      },
      {
        path: 'obyavleniya/*',
        onEnter: (route, redirect) => {
          return redirect('/');
        }
      },
      {
        path: 'accessory/publication-preview/:id',
        component: loadable(() => import(/* webpackChunkName: "accessory-pub-preview" */ './section-news/page-publ')),
        servicePreview: true
      },
      // *** Landings
      {
        path: '*',
        getComponent: landingRoute(landingRoutes)
      },

      /*
       **
       **   DEPRECATE - это всё делалось еще во времена злоебучих археоптериксов.
       *    Всё протухло безнадежно.
       *    TODO После релиза - выпилить нахуй
       **
       **
      */
      /*
      {
        path: 'search',
        mods: 'pageSearch',
        getComponent: asyncComponent(() =>
          import(/!* webpackChunkName: "s-search" *!/ './section-search/page-search'))
      },
      {
        path: 'novosti',
        // menu: 'news',
        getChildRoutes: asyncComponent(() =>
          import(/!* webpackChunkName: "s-news" *!/ './section-news/routes'))
      },
      {
        path: 'nedvizhimost',
        getChildRoutes: asyncComponent(() =>
          import(/!* webpackChunkName: "s-nedvizhimost" *!/ './section-nedvizhimost/routes'))
      },
      {
        path: 'objects',
        getChildRoutes: asyncComponent(() =>
          import(/!* webpackChunkName: "s-obj-ctl" *!/ './section-objects/routes-control'))
      },
      {
        path: 'nedvizhimost',
        getChildRoutes: asyncComponent(() =>
          import(/!* webpackChunkName: "s-obj-clnt" *!/ './section-objects/routes-client'))
      },
      {
        path: 'novostroyki',
        getChildRoutes: asyncComponent(() =>
          import(/!* webpackChunkName: "s-newbuild" *!/ './section-build-new/routes-client'))
      },
      {
        path: 'prodazha',
        getChildRoutes: asyncComponent(() =>
          import(/!* webpackChunkName: "s-prodazha" *!/ './section-prodazha/routes'))
      },
      {
        path: 'specialisty',
        getChildRoutes: asyncComponent(() =>
          import(/!* webpackChunkName: "s-specialisty" *!/ './section-specialisty/routes'))
      },
      {
        path: 'mortgage',
        getComponent: asyncComponent(() =>
          import(/!* webpackChunkName: "s-mortgage" *!/ './section-finance/mortgage'))
      },
      {
        path: 'installments',
        getComponent: asyncComponent(() =>
          import(/!* webpackChunkName: "s-installments" *!/ './section-finance/installments'))
      },
      {
        path: 'compare',
        getComponent: asyncComponent(() =>
          import(/!* webpackChunkName: "s-compare" *!/ './section-compare/page-compare'))
      },
      {
        path: 'real-estate',
        getChildRoutes: asyncComponent(() =>
          import('./section-seo/routes'))
      },
      // *** Пользовательское соглашение
      {
        path: 'soglashenie',
        component: loadable(() => import(/!* webpackChunkName: "terms" *!/ './section-terms'))
      },
      // *** Политика обработки персональных данных
      {
        path: 'confidence',
        component: loadable(() => import(/!* webpackChunkName: "reg-conds" *!/ './section-registration-condition'))
      },
      {
        path: '*',
        getComponent() {
          return import('containers/not-found').then(mod => mod.default);
        },
        status: 404
      }
       */
    ]
  });
}
