import loadable from '@loadable/component';

const landingRoutes = {
  publRubric: {
    component: loadable(() => import(/* webpackChunkName: "publications" */ './section-news/page-list')),
    pagesAccept: true,
    noHeaderSearch: true,
    // mods: 'pageFill',
  },
  publication: {
    component: loadable(() => import(/* webpackChunkName: "publication" */ './section-news/page-publ')),
    noHeaderSearch: true,
    // mods: 'pageFill',
  },
  developer: {
    component: loadable(() => import(/* webpackChunkName: "developer" */ './section-developer')),
    mods: 'pageFill',
    content: 'developer'
  },
  developers: {
    component: loadable(() => import(/* webpackChunkName: "developers" */ './section-developers')),
    content: 'developer',
    pagesAccept: true
  },
  companies: {
    component: loadable(() => import(/* webpackChunkName: "companies" */ './section-companies')),
    content: 'companies',
    pagesAccept: true
  },
  project: {
    component: loadable(() => import(/* webpackChunkName: "project" */ './section-project')),
    mods: 'pageFill',
    content: 'project'
  },
  objects: {
    component: loadable(() => import(/* webpackChunkName: "project" */ './section-project')),
    mods: 'pageFill',
    content: 'objects',
    pagesAccept: true
  },
  complexNews: {
    component: loadable(() => import(/* webpackChunkName: "project" */ './section-project')),
    mods: 'pageFill',
    content: 'complexNews',
    pagesAccept: true
  },
  complexContacts: {
    component: loadable(() => import(/* webpackChunkName: "project" */ './section-project')),
    mods: 'pageFill pageProjectContacts',
    content: 'complexContacts',
    pagesAccept: true
  },
  object: {
    component: loadable(() => import(/* webpackChunkName: "project" */ './section-project')),
    mods: 'pageFill',
    content: 'object'
  },
  addressCatalog: {
    component: loadable(() => import(/* webpackChunkName: "addressCatalog" */ './section-utilities/page-catalog')),
    noHeaderSearch: true,
    pagesAccept: true,
    mods: 'pageFill pageCatalog',
    content: 'addresses'
  },
  catalogHouse: {
    component: loadable(() => import(/* webpackChunkName: "addressCatalog" */ './section-utilities/page-house')),
    noHeaderSearch: true,
    mods: 'pageFill',
    content: 'house'
  },
  /*
  complexComments: {
    component: loadable(() => import(/!* webpackChunkName: "project" *!/ './section-project')),
    mods: 'pageFill',
    content: 'comments',
    pagesAccept: false
  },
  */
  complexTerms: {
    component: loadable(() => import(/* webpackChunkName: "project" */ './section-project')),
    mods: 'pageFill',
    content: 'terms',
    pagesAccept: false
  },
  projects: {
    component: loadable(() => import(/* webpackChunkName: "projects" */ './section-projects')),
    mods: 'pageFill pageSearch',
    pagesAccept: true,
    noHeaderSearch: true
  },
  projectsOnMap: {
    component: loadable(() => import('./section-projects-on-map')),
    mods: 'pageFill pageFixed pageSearch',
    pagesAccept: false,
    noFooter: true,
    noHeaderSearch: true,
    mainSearch: true
  },
  projectsStocks: {
    component: loadable(() => import(/* webpackChunkName: "stocks" */ './section-stocks')),
    content: 'stock',
    pagesAccept: true,
  },
  objectsRoot: {
    component: loadable(() => import(/* webpackChunkName: "searchRoot" */ './section-search/searchRoot')),
  },
  objectsSearch: {
    component: loadable(() => import(/* webpackChunkName: "objects" */ './section-search/page-search')),
    mods: 'pageFill pageObjects',
    content: 'objectsSearch',
    pagesAccept: true,
    noHeaderSearch: true
  }
};

export default landingRoutes;
